<div class="create-job">
  <div class="header">
    <h1>Create Project <span class="faq-icon icon-Info" (click)="openCreateProjectFAQPopup()"></span></h1>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <div class="drag-and-drop-container">
    <ngx-file-drop [multiple]="true" (onFileDrop)="droppedFiles($event)" class="drag-and-drop-container">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <span class="icon icon-Upload" (click)="openFileSelector()"></span>
        <p>Click to <span (click)="openFileSelector()">upload</span> or drag and drop</p>
      </ng-template>
    </ngx-file-drop>
    <ul *ngIf="files.length > 0">
      <li *ngFor="let file of convertedFiles; let i = index">
        <span
          *ngIf="file.name.split('.').pop() !== 'blend'; else blenderIcon"
          class="file-type-icon"
          [ngClass]="file.type"></span>
        <span class="name">{{ file.name }}</span>
        <span class="close icon-Close-Bold" (click)="removeFile(i, file.name)"></span>
      </li>
    </ul>
    <ng-template #blenderIcon>
      <img src="./assets/imgs/blender-icon.svg" alt="" />
    </ng-template>
  </div>
  <form [formGroup]="form" class="form form-modal" autocomplete="off">
    <div class="form__field-group">
      <label>Project name</label>
      <input formControlName="projectName" type="text" placeholder="Enter project name" />
      <span *ngIf="form.get('projectName')?.hasError('required') && form.get('projectName')?.dirty" class="form__error">
        Project name should be present
      </span>
      <span
        *ngIf="form.get('projectName')?.hasError('maxlength') && form.get('projectName')?.dirty"
        class="form__error">
        Project name should not exceed 100 characters
      </span>
      <span *ngIf="form.get('projectName')?.hasError('notEqual') && form.get('projectName')?.dirty" class="form__error">
        Project with this name already exists
      </span>
    </div>
    <app-button
      [attr.disabled]="form.invalid"
      [disabled]="form.invalid"
      (click)="onSubmit()"
      [buttonData]="submitBtn"></app-button>
  </form>
</div>
