<div class="table-section">
  <div class="table-section__header">
    <div class="table-section__header-buttons">
      <app-button
        [buttonData]="uploadFilesBtn"
        [attr.disabled]="isUploadFilesBtnDisabled"
        [disabled]="isUploadFilesBtnDisabled"
        (click)="openUploadFilesPopup()"></app-button>
    </div>
    <div class="table-section__header-search">
      <input type="text" [(ngModel)]="clientService.searchQuery" placeholder="Search" />
      <span class="icon-Search"></span
      ><span
        class="icon-Close close-icon hide-desktop"
        *ngIf="clientService.searchQuery != ''"
        (click)="clientService.clearSearchQuery()"></span>
    </div>
  </div>
  <div class="table-section__body">
    <table
      id="table"
      class="table"
      *ngIf="tableData.length > 0; else emptyTable"
      matSort
      matSortDisableClear
      appClickedOutside
      (matSortChange)="sortData($event)"
      (clickOutside)="clickedOutside()">
      <thead>
        <tr>
          <th mat-sort-header="name" class="wide">Name</th>
          <th mat-sort-header="modified" class="hide-mobile nowrap">Update date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let job of tableData | search : searchColumns : clientService.searchQuery">
          <td class="link" (click)="navigateTo(job)">
            <div class="center" [title]="job.name">
              <ng-container *ngIf="job.extension !== 'blend'; else blenderIcon">
                <span class="file-type-icon" [ngClass]="job.fileIcon"></span>
              </ng-container>
              <ng-template #blenderIcon>
                <img class="image-icon" src="./assets/imgs/blender-icon.svg" alt="" />
              </ng-template>
              {{ additionalService.cropFileNameLength(job.name) }}
            </div>
          </td>
          <td class="hide-mobile nowrap" *ngIf="job.modified; else empty">
            {{ job.modified | date : 'dd.MM.yy, HH:mm' }}
          </td>
          <td class="table__menu">
            <div class="table__menu-wrapper">
              <span class="table__menu-icon icon-Dots-2" (click)="toggleMenu(job, $event)"></span>
              <ul *ngIf="job?.isMenuOpen" class="table__menu-items">
                <li *ngIf="!job.isDir" (click)="openRenameFilePopup(job)">
                  <span class="name">Rename</span>
                  <span class="icon icon-Edit"></span>
                </li>
                <li (click)="removeItem(job, $event)">
                  <span class="name">Remove</span>
                  <span class="icon icon-Close-Bold"></span>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr *ngIf="storageApiService.clientOutputItems$.getValue().length > 0">
          <td class="link" (click)="showRenderResults()">
            <div class="center">
              <img
                class="image-result"
                [src]="storageApiService.clientOutputItems$.getValue()[0].thumbnailLink"
                alt="" />
              Render Result
            </div>
          </td>
          <td class="hide-mobile"></td>
          <td class="hide-mobile"></td>
        </tr>
      </tbody>
    </table>

    <ng-template #emptyTable>
      <div class="table__empty">
        <span *ngIf="!(loaderService.loading$ | async)">No resources found</span>
      </div>
    </ng-template>

    <ng-template #empty>
      <td class="hide-mobile">-</td>
    </ng-template>
  </div>
</div>
