<div class="faqs">
  <div class="header">
    <h1>Blender Rendering FAQ</h1>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <h3>The following provides some information regarding the rendering of Blender files on gaimin.cloud.</h3>
  <div class="faqs-accordion">
    <mat-accordion [displayMode]="'flat'">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Supported Versions</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>Current Blender version supported: 2.93 LTS</li>
          <li>Current Blender Engine supported: EEVEE</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Uploading files to gaimin.cloud</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>Register with gaimin.cloud and/or login to your account.</li>
          <li>Create a new "Project" and upload files to the "New Project".</li>
          <li>
            Upload the main .blend file and any additional assets or folders. Note only the main .blend file is allowed,
            any other files with a .blend extension will not be permitted.
          </li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Estimate of pricing</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>
            The rendering process estimates the cost of rendering based on an analysis of 1% of the frames, extracted at
            intervals of 100 from the submitted file.
          </li>
          <li>If the file has less than 300 frames, the estimate is based on analysis of 3 frames.</li>
          <li>
            The user can accept or reject the estimated cost for rendering. If rejected, no further rendering is
            undertaken.
          </li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Analysis Failure and Warnings</mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>
            Analysis may fail for a number of reasons, warning messages are provided to the user. The next steps or
            actions required from the user depend on the warning received. Some warnings allow the user to ignore the
            warning and continue rendering.
          </li>
          <li>
            All rendering will incur a charge even if the user ignores a warning message, continues rendering and there
            is a problem with the rendering and some stage in the process.
          </li>
          <li>If analysis fails, the user can make any changes to their files, re-upload and restart analysis.</li>
          <li>
            If analysis completes but with warnings, the user can choose to ignore the warning and either start
            rendering or restart the analysis.
          </li>
          <li>
            The user needs to review any warning messages and make their own decision regarding the next action
            (depending on the warning message).
          </li>
          <li>
            The rendering output is provided as sequentially numbered frames in .jpeg format. The user downloads the
            rendered output file and re-imports the .jpeg files into Blender to create their required final file.
          </li>
          <li>All Project data will be automatically deleted after 1 month of inactivity on the Project.</li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
