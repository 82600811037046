import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import * as mime from 'mime';
import { ProjectService } from './project.service';
import { UploadedFiles, ResourceItem } from '../interfaces';
import { JOB_STATUS, TRANSACTION_TYPE } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AdditionalService {
  previousValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get isEnableToEditResources(): boolean {
    const jobStatus = this.projectService.selectedJobData$.getValue().status;
    const isJobWithWarnings: boolean = this.projectService.selectedJobData$.getValue().warnings.length > 0;

    return (
      jobStatus === JOB_STATUS.NEW ||
      jobStatus === JOB_STATUS.ANALYSIS_FAILED ||
      (jobStatus === JOB_STATUS.ANALYZED && isJobWithWarnings)
    );
  }

  getTransactionName(code: string) {
    switch (code) {
      case 'CHARGE':
        return TRANSACTION_TYPE.CHARGE;
      case 'BONUS':
        return TRANSACTION_TYPE.BONUS;
      default:
        return TRANSACTION_TYPE.PAYMENT;
    }
  }

  constructor(private projectService: ProjectService, private toastrService: ToastrService) {}

  normalizeAmount(keyPressed: string, amount: string, countNumbersAfterDot: number) {
    const validValues = '1234567890.';
    const beforeAndAfterDot = amount.split('.');

    if (beforeAndAfterDot.length > 2) {
      return this.previousValue$.getValue();
    }

    if (validValues.includes(keyPressed)) {
      if (beforeAndAfterDot[0].startsWith('0') && beforeAndAfterDot[0].length > 1) {
        return amount.slice(1);
      }

      return !(beforeAndAfterDot.length > 2 || beforeAndAfterDot[1]?.length > countNumbersAfterDot)
        ? amount
        : amount.slice(0, -1);
    }
    return amount.split(keyPressed).join('');
  }

  normalizeStatus(status: string): string {
    const normalizedStatus = status.replace(/_/g, ' ').toLowerCase();
    return normalizedStatus.charAt(0).toUpperCase() + normalizedStatus.slice(1);
  }

  cropFileNameLength(name: string, length = 30) {
    return name.length >= length ? name.slice(0, 30) + '...' : name;
  }

  removeRepeatedFiles(files: NgxFileDropEntry[]) {
    const uniqueFiles: NgxFileDropEntry[] = [];

    files.forEach((file) => {
      if (!uniqueFiles.some((item) => item.relativePath === file.relativePath)) {
        uniqueFiles.push(file);
      } else {
        const resourceType = file.fileEntry.isDirectory ? 'Folder' : 'File';
        this.toastrService.warning(`${resourceType} with the name '"${file.relativePath}"' already exists`);
      }
    });

    return uniqueFiles;
  }

  convertFilesArray(files: NgxFileDropEntry[]) {
    const convertedFiles: UploadedFiles[] = [];

    files.forEach((item) => {
      if (item.fileEntry.isFile) {
        const fileEntry = item.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          let type = '';
          switch (true) {
            case file.type.indexOf('image/') !== -1:
              type = 'icon-Image-File';
              break;
            case file.type.indexOf('video/') !== -1:
              type = 'icon-Video-File';
              break;
            case file.type.indexOf('audio/') !== -1:
              type = 'icon-Audio-File';
              break;
            default:
              type = 'icon-Generic-File';
          }
          convertedFiles.push({ type: type, name: item.relativePath });
        });
      } else {
        convertedFiles.push({ type: 'icon-folder', name: item.relativePath });
      }
    });

    return convertedFiles;
  }

  convertProjectName(name: string) {
    return name.replace(/\s+/g, '_');
  }

  getProjectItemsWithIcons(data: ResourceItem[]) {
    return data.map((item) => {
      let typeByExtension = mime.getType(item.extension);
      let type = '';
      if (typeByExtension) {
        switch (true) {
          case typeByExtension.indexOf('image/') !== -1:
            type = 'icon-Image-File';
            break;
          case typeByExtension.indexOf('video/') !== -1:
            type = 'icon-Video-File';
            break;
          case typeByExtension.indexOf('audio/') !== -1:
            type = 'icon-Audio-File';
            break;
          default:
            type = 'icon-Generic-File';
        }
        item.fileIcon = type;
      } else {
        item.fileIcon = 'icon-folder';
      }
      return item;
    });
  }

  placeholderCounter(length: number) {
    return new Array(length);
  }

  getRenderPercent(framesCount: number = 0, finishedFramesCount: number = 0) {
    if (framesCount == 0) {
      return 0;
    }
    const percent = (finishedFramesCount * 100) / framesCount || 0;
    if (percent < 1) {
      return parseFloat(percent.toFixed(1));
    } else {
      return Math.floor(percent);
    }
  }

  sortData(sort: Sort | any, data: any) {
    return data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'createdOn':
          return this.compare(a.createdOn, b.createdOn, isAsc);
        case 'modified':
          return this.compare(a.modified, b.modified, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'jobCost':
          return this.compare(a.jobCost || 0, b.jobCost || 0, isAsc);
        case 'render':
          const percentA = this.getRenderPercent(a.framesCount, a.finishedFramesCount);
          const percentB = this.getRenderPercent(b.framesCount, b.finishedFramesCount);

          if (percentA === percentB) {
            return this.compare(a.createdOn, b.createdOn, isAsc);
          }
          return this.compare(percentA, percentB, isAsc);
        case 'transaction_job':
          return this.compare(a.job?.name, b.job?.name, isAsc);
        case 'amount':
          return this.compare(a.type.type + a.amount, b.type.type + b.amount, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === null || a === undefined) {
      return isAsc ? 1 : -1;
    }
    if (b === null || b === undefined) {
      return isAsc ? -1 : 1;
    }
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
