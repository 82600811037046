import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { StorageApiService } from '../../services/storage-api.service';
import { AdditionalService } from '../../services/additional.service';
import { ClientService } from '../../services/client.service';
import { LoaderService } from '../../services/loader.service';
import { UploadFilesModalComponent } from '../modals/upload-files-modal/upload-files-modal.component';
import { EditFileNameModalComponent } from '../modals/edit-file-name-modal/edit-file-name-modal.component';
import { ClientJobItem, ResourceItem, UploadProgressData, Button } from '../../interfaces';
import { JOB_STATUS } from '../../enums';

@Component({
  selector: 'app-project-item-table',
  templateUrl: './project-item-table.component.html',
  styleUrls: ['./project-item-table.component.scss']
})
export class ProjectItemTableComponent implements OnInit, OnDestroy {
  tableData: ResourceItem[] = [];
  searchColumns: string[] = ['name'];
  isUploadFilesBtnDisabled: boolean = false;

  readonly uploadFilesBtn: Button = {
    name: 'Upload Files'
  };
  private sortParams: Sort = { active: 'modified', direction: 'desc' };
  private clientResourcesItemsSubscription: Subscription | undefined;
  private selectedJobDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private toastrService: ToastrService,
    public dialogService: MatDialog,
    public clientService: ClientService,
    public projectService: ProjectService,
    public storageApiService: StorageApiService,
    public additionalService: AdditionalService,
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.clientResourcesItemsSubscription = this.storageApiService.clientResourcesItems$.subscribe(
      (response: ResourceItem[]) => {
        console.log('tableData', response);
        this.tableData = this.storageApiService.filterClientResourcesByPath();
        this.tableData = this.additionalService.getProjectItemsWithIcons(this.tableData);
        this.sortData(this.sortParams);
      }
    );

    this.selectedJobDataSubscription = this.projectService.selectedJobData$.subscribe((response: ClientJobItem) => {
      this.isUploadFilesBtnDisabled = [
        JOB_STATUS.RENDERING,
        JOB_STATUS.PAUSED,
        JOB_STATUS.ANALYZE_IN_PROGRESS,
        JOB_STATUS.COMPLETED
      ].includes(response.status)
        ? true
        : false;
    });
  }

  navigateTo(item: ResourceItem) {
    if (item.isDir) {
      this.router.navigate([`/projects`, this.projectService.selectedJobId], {
        queryParams: { path: item.path + item.name + '/' },
        queryParamsHandling: 'merge'
      });
    }
  }

  toggleMenu(job: ResourceItem, event: any) {
    event.stopPropagation();
    this.clickedOutside();
    let itemIndex = this.tableData.findIndex((item: ResourceItem) => item.name == job.name);
    this.tableData[itemIndex].isMenuOpen = !this.tableData[itemIndex].isMenuOpen;
  }

  clickedOutside() {
    this.tableData.forEach((item: ResourceItem) => {
      item.isMenuOpen = false;
    });
  }

  openRenameFilePopup(item: ResourceItem) {
    this.clickedOutside();
    if (this.additionalService.isEnableToEditResources) {
      this.dialogService.open(EditFileNameModalComponent, {
        panelClass: 'dialog-overlay-pane',
        data: item
      });
    } else {
      const normalizedStatus: string = this.additionalService.normalizeStatus(
        this.projectService.selectedJobData$.getValue().status
      );
      this.toastrService.warning(`The project status is "${normalizedStatus}"`, 'The file name could not be changed');
    }
  }

  removeItem(item: ResourceItem, event: any) {
    event.stopPropagation();
    this.clickedOutside();
    const jobData: ClientJobItem = this.projectService.selectedJobData$.getValue();
    if (this.additionalService.isEnableToEditResources) {
      const path = item.path + item.name;
      this.storageApiService.removeClientResource(jobData.id, path).subscribe({
        next: (response) => {
          if (response.success) {
            const fullPathId = jobData.id + '/' + this.storageApiService.path + item.name;
            const uploadProgressData: UploadProgressData = this.storageApiService.uploadProgressData$.getValue();
            delete uploadProgressData[fullPathId];
            this.storageApiService.uploadProgressData$.next(uploadProgressData);
            this.updateFileChanges(jobData.id);
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    } else {
      const jobStatus: string = this.additionalService.normalizeStatus(jobData.status || 'undefined');
      this.toastrService.warning(`The project status is "${jobStatus}"`, 'The file could not be removed');
    }
  }

  updateFileChanges(jobId: number) {
    this.clientService.fileChangesRequest(jobId).subscribe({
      next: () => {
        this.storageApiService.refreshClientInputResources(jobId);
      },
      error: (error) => {
        this.toastrService.error(error.message);
      }
    });
  }

  openUploadFilesPopup() {
    this.dialogService.open(UploadFilesModalComponent, { panelClass: 'dialog-overlay-pane' });
  }

  showRenderResults() {
    this.storageApiService.isRenderResultsSectionOpen = !this.storageApiService.isRenderResultsSectionOpen;
    // window.scroll({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  }

  sortData(sort: Sort | any) {
    this.sortParams = sort;

    if (!sort.active || sort.direction === '') {
      return;
    }

    this.tableData = this.additionalService.sortData(sort, this.tableData);

    if (this.clientService.searchQuery !== '') {
      const tempSearchVal = this.clientService.searchQuery;
      this.clientService.searchQuery = '';
      this.changeDetector.detectChanges();
      this.clientService.searchQuery = tempSearchVal;
    }
  }

  ngOnDestroy(): void {
    this.dialogService.closeAll();
    this.clientResourcesItemsSubscription?.unsubscribe();
    this.selectedJobDataSubscription?.unsubscribe();
  }
}
